<template>
  <div id="app">
    <el-container class="app-container">
      <el-header>
        <div class="app-header common-layout">
          <router-link class="app-header-logo" to="/"></router-link>
          <div class="app-header-title"></div>
          <div v-if="!isLogin && !isForgot" class="app-header-right">
            <el-dropdown trigger="click" @command="handleCommand">
              <span class="el-dropdown-link">
                <el-avatar size="small" style="margin-right: 10px"
                  :src="myPageLogo"
                ></el-avatar>
              </span>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item v-if="!isMyPage" command="goMyPage">マイページ</el-dropdown-item>
                <el-dropdown-item command="goInformations">インフォメーション</el-dropdown-item>
                <el-dropdown-item command="goCourses">講座受講一覧</el-dropdown-item>
                <el-dropdown-item command="goQas">お問い合わせ</el-dropdown-item>
                <el-dropdown-item command="goSetprofile">本人情報</el-dropdown-item>
                <el-dropdown-item command="goSetpassword">パスワード変更</el-dropdown-item>
                <el-dropdown-item command="logout">ログアウト</el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
            <span>{{userNameKanji}}</span>
          </div>
        </div>
      </el-header>
      <el-main>
        <div class="app-main common-layout">
          <router-view v-if="isRouterAlive"></router-view>
        </div>
      </el-main>
      <el-footer height="auto"  v-if="!isHtmlPage">
        <PCFooter />
        <PhoneFooter />
      </el-footer>
      <el-backtop></el-backtop>
    </el-container>
  </div>
</template>

<script>
import Cookies from 'js-cookie';
import request from '@/utils/request';
// import footerLogo from './assets/footer_logo.png';
// import footerLogo from './assets/logo.gif';
import myPageLogo from './assets/user.png';
import PCFooter from "./components/PCFooter.vue";
import PhoneFooter from "./components/PhoneFooter.vue";

export default {
  name: 'App',
  components: {
    PCFooter,
    PhoneFooter
  },
  provide() {
    return {
      reload: this.reload,
      isLogin: this.isLogin,
      isForgot: this.isForgot,
      isHtmlPage: this.isHtmlPage,
    };
  },
  data() {
    return {
      myPageLogo,
      // footerLogo,
      isRouterAlive: true,
      activeIndex: '1',
      isLogin: false,
      isForgot: false,
      isMyPage: false,
      isHtmlPage: false,
      messageNum: 0,
      footerList: [
        [
          {
            title: '重要事項',
            items: [
              {
                title: '個人情報保護方針',
                to: '/privacy.html',
              },
              {
                title: '利用規約',
                to: '/terms.html',
              },
            ],
          },
        ],
        [
          {
            title: '重要事項',
            items: [
              {
                title: '免責事項',
                to: '/disclaimer.html',
              },
              {
                title: '推奨ブラウザ',
                to: '/recommendedBrowser.html',
              },
            ],
          },
        ],
        [
          {
            title: '重要事項',
            items: [
              {
                title: 'ご利用ガイド',
                to: '/guide/guide.html',
              },
              {
                title: 'よくあるご質問',
                to: '/guide/faq.html',
              },
            ],
          },
        ],
        [
          {
            title: '受講受験ナビ',
            items: [
              {
                title: '運営会社',
                to: 'https://www.tkhs.co.jp/company/outline.html',
              },
            ],
          },
        ],
        [],
        [],
      ],
      userNameKanji: ''
    };
  },
  watch: {
    $route(to) {
      this.isMyPage = /^\/mypage/.test(to.path);
      this.isLogin = to.name === 'Login';
      this.isForgot = to.name === 'Forgot';
      this.isHtmlPage = /^\/showHtml/.test(to.path);
      if (Cookies.get('userNameKanji') !== null && Cookies.get('userNameKanji')!== '') {
        this.userNameKanji = Cookies.get('userNameKanji')
      }
    },
  },
  computed: {
    badgeRight() {
      if (this.messageNum === 0) {
        return '0';
      }
      if (this.messageNum < 10) {
        return '10px';
      }
      return '20px';
    },
  },
  methods: {
    reload() {
      this.isRouterAlive = false;
      this.$nextTick(() => {
        this.isRouterAlive = true;
      });
    },
    goPage(path) {
      this.$router.push({
        path,
      }).catch(() => {});
    },
    goMyPage() {
      this.goPage('/mypage');
    },
    // goMyQas() {
    //   this.goPage('/mypage/qas');
    // },
    goInformations() {
      this.goPage('/mypage/informations');
    },
    goCourses() {
      this.goPage('/mypage/courses');
    },
    goQas() {
      this.goPage('/mypage/qas');
    },
    goSetprofile() {
      this.goPage('/mypage/setprofile');
    },
    goSetpassword() {
      this.goPage('/mypage/setpassword');
    },
    handleCommand(command) {
      this[command]();
    },
    logout() {
      request('logout').finally(() => {
        Cookies.remove('token');

        this.$router.push({
          path: '/login',
        }).catch(() => {});
      });
    },
  },
};
</script>

<style lang="less" scoped>
#app {
  height: 100%;
  .app-container {
    display: flex;
    flex-direction: column;
    min-height: 100%;
    position: relative;
    overflow: hidden;

    .el-header {
      position: fixed;
      z-index: 10000;
      width: 100%;
      padding: 0;
      background: #d4ecf4;

      .app-header {
        position: relative;
        color: #0f3264;

        .app-header-logo {
          width: 200px;
          height: 100%;
          float: left;
          position: relative;
          background: url(assets/studypack_logo.png) no-repeat;
          background-size: contain;
          background-position: center;
        }

        .app-header-title {
          position: absolute;
          left: 50%;
          top: 50%;
          transform: translate(-50%, -50%);
          font-size: 20px;
        }

        .app-header-right {
          float: right;
          height: 100%;
          display: flex;
          align-items: center;

          .app-header-badge {
            font-size: 20px;
          }
          & > div {
            padding: 0 10px;
            cursor: pointer;
            position: relative;
            transition: color .3s;
            font-size: 14px;

            &:hover {
              color: #ccc;
            }

            &:after {
              display: block;
              content: "";
              position: absolute;
              top: 0;
              right: 0;
              height: 100%;
              width: 1px;
              // background-color: #94CE58;
              background-color: #ddd;
            }

            &:first-child {
              padding-left: 0;
            }
            &:last-child {
              padding-right: 0;

              &:after {
                display: none;
              }
            }

            span {
              font-size: 14px;
              vertical-align: middle;
            }
            .app-header-bell {
              margin-right: 10px;
              font-size: 20px;
              vertical-align: middle;
            }
          }
        }
      }
    }
    .el-main {
      flex: 1 0 auto;
      display: flex;
      flex-direction: column;
      width: 100%;
      overflow: auto;
      margin-top: 60px;
      padding: 0;

      .app-main {
        flex: 1;
        // margin: 0 auto;
        // max-width: 1400px;
        // padding: 0 24px;
      }
    }
    .el-footer {
      background: #d4ecf4;
    }
  }
}
@media (max-width: 767px) {
  #app {
    .app-container {
      .el-header {
        height: 40px!important;
        .app-header {
          padding: 0 5px;
          .app-header-logo {
            width: 120px;
          }
          .app-header-title {
            font-size: 14px;
          }
        }
      }
      .el-main {
        margin-top: 40px;
      }
      .el-backtop {
        display: none;
        background-color: #ccc
      }
    }
  }
}
</style>
