<template>
  <div class="app-footer-phone common-layout">
    <div class="app-footer-content">
      <router-link class="app-footer-logo" to="/"></router-link>
      <div class="app-footer-list">
        <div class="app-footer-list-item-major-wrap">
          <div class="app-footer-list-item-major">
            <a href="/guide/guide.html" target="_blank">
              <el-avatar size="small"
                :src="guideImg"
              ></el-avatar>
              <span>ご利用ガイド</span>
            </a>
          </div>
          <div class="app-footer-list-item-major">
            <a href="/guide/faq.html" target="_blank">
              <el-avatar size="small"
                :src="howtoImg"
              ></el-avatar>
              <span>よくあるご質問</span>
            </a>
          </div>
        </div>
        <div class="app-footer-list-minor-wrap">
          <el-dropdown trigger="click" placement="top" @visible-change="clickDropdown">
            <span class="el-dropdown-link">
              <span class="el-dropdown-link-open">{{dropdownIsOpen ? '-' : '+'}}</span> サポートメニュー
            </span>
            <el-dropdown-menu class="app-footer-list-minor" slot="dropdown">
              <el-dropdown-item>
                <a class="app-footer-list-item-minor" href="/privacy.html" target="_blank">個人情報保護方針</a>
              </el-dropdown-item>
              <el-dropdown-item>
                <a class="app-footer-list-item-minor" href="/terms.html" target="_blank">利用規約</a>
              </el-dropdown-item>
              <el-dropdown-item>
                <a class="app-footer-list-item-minor" href="/disclaimer.html" target="_blank">免責事項</a>
              </el-dropdown-item>
              <el-dropdown-item>
                <a class="app-footer-list-item-minor" href="/recommendedBrowser.html" target="_blank">推奨ブラウザ</a>
              </el-dropdown-item>
              <el-dropdown-item>
                <a class="app-footer-list-item-minor" href="https://www.tkhs.co.jp/company/outline.html" target="_blank">運営会社</a>
              </el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </div>
      </div>
    </div>
    <div class="app-footer-copyright">
      <span>Copyright Ⓒ 2021 東京法規出版 All Rights Reserved.</span>
    </div>
  </div>
</template>

<script>
import guideImg from '@/assets/guide.png';
import howtoImg from '@/assets/howto.png';
import myPageLogo from '@/assets/user.png';

export default {
  name: 'PhoneFooter',
  data() {
    return {
      guideImg,
      myPageLogo,
      howtoImg,
      dropdownIsOpen: false,
    };
  },
  methods: {
    clickDropdown(dropdownIsOpen) {
      this.dropdownIsOpen = dropdownIsOpen;
    },
  },
};
</script>

<style lang="less" scoped>
  .el-footer .app-footer-phone {
    display: none;
    padding: 20px 0;
    flex-direction: column;
    width: 100%;
    color: #0f3264;

    .app-footer-content{
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      .app-footer-logo {
        width: 200px;
        height: 60px;
        display: block;
        background: url(../assets/studypack_logo.png) no-repeat;
        background-size: contain;
        background-position: center;
      }

      .app-footer-list {
        flex: 1;

        .app-footer-list-item-major-wrap {
          display: flex;
          justify-content: space-around;
          margin-bottom: 25px;
          padding: 0 20px;

          .app-footer-list-item-major a {
            display: flex;
            align-items: center;
            color: rgb(15, 51, 100);
            font-weight: bold;
            height: 30px;
            line-height: 30px;

            .el-avatar {
              background-color: transparent;
            }
          }
        }

        .app-footer-list-minor-wrap {
          text-align: center;
          margin-bottom: 15px;

          .el-dropdown-link {
            color: rgb(15, 51, 100);
            border: solid 1px rgb(15, 51, 100);
            border-radius: 4px;
            padding: 8px 20px;

            .el-dropdown-link-open {
              display: inline-block;
              width: 12px;
            }
          }
        }
      }
    }

    .app-footer-copyright {
      margin-top: 5px;
      margin-bottom: 10px;
      text-align: center;

      span {
        vertical-align: middle;
        font-size: 12px;
      }
      .el-link {
        color: #0f3264;

        &:hover {
          color: #409EFF;
        }
      }
    }
  }

  @media (max-width: 767px) {
    #app {
      .el-footer .app-footer-phone {
        display: flex;
      }
    }
  }
</style>
