import Vue from 'vue';
import {
  Container, Header, Main, Aside, Footer, Menu, MenuItem, Submenu, Badge,
  Avatar, Image, Select, Option, Input, Button, Pagination, Loading,
  Divider, Link, Form, FormItem, DatePicker, Alert, Radio, Table, TableColumn,
  Dialog, Card, Checkbox, RadioGroup, CheckboxGroup, Breadcrumb, BreadcrumbItem, Row, Col,
  Message, Backtop, Dropdown, DropdownMenu, DropdownItem, Icon,Tabs,TabPane
} from 'element-ui';
import lang from 'element-ui/lib/locale/lang/ja';
import locale from 'element-ui/lib/locale';

locale.use(lang);

Vue.use(Container);
Vue.use(Header);
Vue.use(Main);
Vue.use(Aside);
Vue.use(Footer);
Vue.use(Menu);
Vue.use(MenuItem);
Vue.use(Submenu);
Vue.use(Badge);
Vue.use(Avatar);
Vue.use(Image);
Vue.use(Select);
Vue.use(Option);
Vue.use(Input);
Vue.use(Button);
Vue.use(Pagination);
Vue.use(Loading);
Vue.use(Divider);
Vue.use(Link);
Vue.use(Form);
Vue.use(FormItem);
Vue.use(DatePicker);
Vue.use(Alert);
Vue.use(Radio);
Vue.use(Table);
Vue.use(TableColumn);
Vue.use(Dialog);
Vue.use(Card);
Vue.use(Checkbox);
Vue.use(RadioGroup);
Vue.use(CheckboxGroup);
Vue.use(Breadcrumb);
Vue.use(BreadcrumbItem);
Vue.use(Row);
// Vue.use(Message);
Vue.use(Col);
Vue.use(Backtop);
Vue.use(Dropdown);
Vue.use(DropdownMenu);
Vue.use(DropdownItem);
Vue.use(Icon);
Vue.use(Tabs);
Vue.use(TabPane);

Vue.prototype.$message = Message;
