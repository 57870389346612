import Vue from 'vue';
import VueRouter from 'vue-router';
// import request from '@/utils/request';
import Cookies from 'js-cookie';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'Home',
    component: () => import('@/views/Home.vue'),
    meta: {
      requireAuth: true,
    },
  },
  {
    path: '/showPDF/:courseId/:subjectId/:unitId/:materialId',
    name: 'showPDF',
    component: () => import('@/views/ShowPDF.vue'),
    meta: {
      requireAuth: true,
    },
  },
  {
    path: '/showVideo/:courseId/:subjectId/:unitId/:materialId',
    name: 'showVideo',
    component: () => import('@/views/ShowVideo.vue'),
    meta: {
      requireAuth: true,
    },
  },
  {
    path: '/showHtml/:courseId/:subjectId/:unitId/:materialId',
    name: 'showHtml',
    component: () => import('@/views/ShowHtml.vue'),
    meta: {
      requireAuth: true,
    },
  },
  {
    path: '/index.html',
    name: 'Home',
    component: () => import('@/views/Home.vue'),
    meta: {
      requireAuth: true,
    },
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import('@/views/Login.vue'),
  },
  {
    path: '/setpassword',
    component: () => import('@/views/Password.vue'),
  },
  {
    path: '/register',
    name: 'Register',
    component: () => import('@/views/Register.vue'),
  },
  {
    path: '/forgot',
    name: 'Forgot',
    component: () => import('@/views/Forgot.vue'),
  },
  {
    path: '/rules',
    name: 'Rules',
    component: () => import('@/views/Rules.vue'),
  },
  {
    path: '/inforules',
    name: 'Inforules',
    component: () => import('@/views/Inforules.vue'),
  },
  {
    path: '/course/:id',
    name: 'Course',
    component: () => import('@/views/Course.vue'),
    meta: {
      requireAuth: true,
    },
  },
  {
    path: '/subject/:id/:courseId',
    name: 'Subject',
    component: () => import('@/views/Subject.vue'),
    meta: {
      requireAuth: true,
    },
  },
  {
    path: '/unit/:id/:courseId/:subjectId',
    name: 'Unit',
    component: () => import('@/views/Unit.vue'),
    meta: {
      requireAuth: true,
    },
  },
  {
    path: '/material/:id/:courseId/:subjectId/:unitId',
    name: 'Material',
    component: () => import('@/views/Material.vue'),
    meta: {
      requireAuth: true,
    },
  },
  {
    path: '/test',
    name: 'Test',
    component: () => import('@/views/Test.vue'),
    meta: {
      requireAuth: true,
    },
  },
  {
    path: '/test/:id/result',
    name: 'TestResult',
    component: () => import('@/views/TestResult.vue'),
    meta: {
      requireAuth: true,
    },
  },
  {
    path: '/mypage',
    component: () => import('@/views/MyPage/Index.vue'),
    meta: {
      requireAuth: true,
    },
    children: [
      {
        path: '',
        redirect: '/mypage/informations',
      },
      {
        path: 'informations',
        component: () => import('@/views/MyPage/Informations.vue'),
      },
      {
        path: 'information/:id',
        component: () => import('@/views/MyPage/Information.vue'),
      },
      {
        path: 'courses',
        component: () => import('@/views/MyPage/Courses.vue'),
      },
      {
        path: 'feedbacks',
        component: () => import('@/views/MyPage/Feedbacks.vue'),
      },
      {
        path: 'qas',
        component: () => import('@/views/MyPage/QaNew.vue'),
      },
      {
        path: 'qasuccess',
        component: () => import('@/views/MyPage/QaSuccess.vue'),
      },
      // {
      //   path: 'qas',
      //   component: () => import('@/views/MyPage/Qas.vue'),
      // },
      // {
      //   path: 'qa/new',
      //   component: () => import('@/views/MyPage/QaNew.vue'),
      // },
      // {
      //   path: 'qa/:id',
      //   component: () => import('@/views/MyPage/Qa.vue'),
      // },
      {
        path: 'certifications',
        component: () => import('@/views/MyPage/Certifications.vue'),
      },
      {
        path: 'setprofile',
        component: () => import('@/views/MyPage/Profile.vue'),
      },
      {
        path: 'setpassword',
        component: () => import('@/views/MyPage/Password.vue'),
      },
      // {
      //   path: 'setmail',
      //   component: () => import('@/views/MyPage/Mail.vue'),
      // },
    ],
  },
  {
    path: '*',
    name: 'NotFound',
    component: () => import('@/views/NotFound.vue'),
  },
];

const router = new VueRouter({
  mode: 'history',
  routes,
});

router.beforeEach((to, from, next) => {
  // const loginStatus = await request('isLogin');
  const token = Cookies.get('token');
  window.scrollTo(0, 0)
  if (to.matched.some((record) => record.meta.requireAuth)) {
    if (token) {
      next();
    } else {
      next({
        path: '/login',
        query: { redirect: to.fullPath },
      });
    }
  } else {
    next();
  }
});

export default router;
