import axios from 'axios';
import services from '@/utils/services';
// import { Message } from 'element-ui';
import { Notification } from 'element-ui';
import Cookies from 'js-cookie';

const TIMEOUT = 5000000;
const headers = {
  'Content-Type': 'application/json',
};

function axiosRequest(params = {}) {
  if (Cookies.get('token')) {
    headers.token = Cookies.get('token');
  }

  return new Promise((resolve, reject) => {
    axios({
      timeout: TIMEOUT,
      headers,
      ...params,
    }).then((res) => {
      if (res.status < 200 || res.status >= 300) {
        reject(res);
      } else {
        if (res.data.code === 0) {
          resolve(res.data.data);
          return;
        }
        // Message({
        //   message: res.data.msg,
        //   offset: 70,
        // });
        Notification.closeAll()
        Notification({
          title: 'エラー',
          message: res.data.msg,
          duration: 0,
          offset: 75,
        });
        reject(res.data.msg || 'error');
        if(res.data.code === 401){
          Cookies.remove('token');
        }
      }
    })
      .catch((error) => {
        // Message({
        //   message: error,
        //   offset: 70,
        // });
        Notification({
          title: 'エラー',
          message: error,
          duration: 0,
          offset: 75,
        });
        reject(error || 'request failed');
      });
  });
}

const normalRequest = {
  get: (url, params) => axiosRequest({
    method: 'get',
    url,
    params,
  }),
  post: (url, data) => axiosRequest({
    method: 'post',
    url,
    data,
  }),
};

const request = (serviceName, params) => {
  if (!services[serviceName]) {
    return Promise.reject(new Error('Request name is not defined'));
  }
  const {
    method = 'get',
    url = '',
  } = services[serviceName];

  return normalRequest[method](url, params);
};


export default request;
