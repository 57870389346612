const services = {
  isLogin: {
    url: '/api/isLogin',
    desc: '判断是否登录',
  },
  login: {
    method: 'post',
    url: '/api/login',
    desc: '登录',
  },
  logout: {
    method: 'post',
    url: '/api/logout',
    desc: '登出',
  },
  resetPassword: {
    method: 'post',
    url: '/api/user/password/remind',
    desc: '重置密码',
  },
  firstResetPassword: {
    method: 'post',
    url: '/api/user/password/update',
    desc: '首次登录重置密码',
  },
  updatePassword: {
    method: 'post',
    url: '/api/mypage/password/update',
    desc: '修改密码',
  },
  inquirySendMail: {
    method: 'post',
    url: '/api/user/inquiryMail',
    desc: '問い合わせメール(送信)',
  },
  getCourseList: {
    url: '/api/course/list',
    desc: '获取课程列表',
  },
  getCourseDetail: {
    url: '/api/course/detail',
    desc: '获取课程详细',
  },
  applyCourse: {
    method: 'post',
    url: '/api/course/reg',
    desc: '申请课程',
  },
  getSubjectDetail: {
    url: '/api/course/subject/detail',
    desc: '获取教科详细',
  },
  getUnitDetail: {
    url: '/api/course/subject/unit/detail',
    desc: '获取单元详细',
  },
  getMaterialDetail: {
    url: '/api/course/subject/unit/material/detail',
    desc: '获取教材详细',
  },
  downloadCertificate: {
    method: 'downloadPost',
    url: '/api/course/certificate',
    desc: '下载修了证',
  },
  getSubjectList: {
    url: '/api/getSubjectList',
    desc: '获取教科列表',
  },
  getUnitList: {
    url: '/api/getUnitList',
    desc: '获取单元列表',
  },
  getMaterialList: {
    url: '/api/getMaterialList',
    desc: '获取教材列表',
  },
  getMyCourseList: {
    url: '/api/mypage/courselist',
    desc: '获取我的课程列表',
  },
  getInformationList: {
    url: '/api/mypage/infolist',
    desc: '获取我的消息列表',
  },
  getInformationDetail: {
    url: '/api/mypage/info',
    desc: '获取我的消息详情',
  },
  getQaList: {
    url: '/api/getQaList',
    desc: '获取我的问答列表',
  },
  getQaDetail: {
    url: '/api/getQaDetail',
    desc: '获取我的问答详情',
  },
  getMyProfile: {
    url: '/api/mypage/user',
    desc: '获取我的资料',
  },
  setMyProfile: {
    method: 'post',
    url: '/api/mypage/user/update',
    desc: '修改我d资料',
  },
  getMyZipInfo: {
    method: 'post',
    url: '/api/mypage/zipInfo',
    desc: '获取邮编信息',
  },
  getTestList: {
    method: 'post',
    url: '/api/course/subject/unit/test',
    desc: '获取测试题',
  },
  submitTest: {
    method: 'post',
    url: '/api/course/subject/unit/test/next',
    desc: '提交测试题答案',
  },
  submitTestLast: {
    method: 'post',
    url: '/api/course/subject/unit/test/submit',
    desc: '提交测试题最后答案',
  },
  endTimeUpdate: {
    method: 'post',
    url: '/api/course/subject/unit/material/endTimeUpdate',
    desc: 'endTimeUpdate',
  },
  getTeachingMaterialByCourseList: {
    method: 'post',
    url: '/api/course/getTeachingMaterialByCourseList',
    desc: 'getTeachingMaterialByCourseList',
  },
  getTeachingMaterialShow: {
    method: 'post',
    url: '/api/course/getTeachingMaterialShow',
    desc: 'getTeachingMaterialShow',
  },
  getTeachingMaterialByStudent: {
    url: '/api/course/getTeachingMaterialByStudent',
    desc: 'getTeachingMaterialByStudent',
  },
  download: {
      method: 'downloadPost',
      url: '/api/course/subject/unit/material/download',
      desc: 'download',
  },
  noPassedAnsweres: {
    method: 'post',
    url: '/api/course/subject/unit/test/noPassedAnsweres',
    desc: '不合格問題一覧',
  },
};

export default services;
